export default /* glsl */ `

#ifndef saturate
// may have been defined in <common> or <tonemapping_pars_fragment>
#define saturate(a) clamp( a, 0.0, 1.0 )
#endif

//

// DEPTH

//

const highp float EDGE_DISTANCE = .005; // edge values (0 and 1 ish) aren't packed properly

vec3 packDepthToRGB(in highp float v) {
  v = v * (1. - EDGE_DISTANCE * 2.) + EDGE_DISTANCE;

  v += 0.5 / ( 255.0*255.0 );

  const highp vec4 packFactor = vec4( 1.0, 255.0, 65025.0, 16581375.0 );
  highp vec4 res = fract( v * packFactor );
  res.xyz -= res.yzw * (1.0/255.0);

  return res.zyx;
}

highp float unpackRGBToDepth( in highp vec3 v ) {

  const float recip255 = 1. / 255.;
  v = floor( v * 255. + .5 ) * recip255; // iOS fix: reading UnsignByte buffer values have an error of about 10% / 255

  const highp vec3 unpackFactor = 1.0 / vec3( 1.0, 255.0, 65025.0 );
 	highp float depth = dot( v.zyx, unpackFactor );
  depth -= 0.5 / ( 255.0*255.0 );
  depth = (depth - EDGE_DISTANCE) * (1. / (1. - EDGE_DISTANCE * 2.));
  return depth;
}

highp float unpackRGBAToDepth_Improved( in vec4 v ) {
  v = floor(v * 255. + .5) / 255.;
  return dot( v, UnpackFactors );
}

//

// NORMAL

//

// expects normals normalized
vec4 packNormalToRGBA( vec3 normal ) {
  normal.x = saturate(normal.x * .5 + .5);
  normal.y = saturate(normal.y * .5 + .5);
  // assert: normal.xy is [0, 1]
  // next step is tricky: we'll store the z sign in x, and repack it to the range [0,1]
	float zSign = sign( normal.z );
	if( zSign == 0. ) zSign = 1.;
	normal.x *= zSign;
  normal.x = normal.x * .5 + .5;

  return vec4(
    packDepthToRG(normal.x),
    packDepthToRG(normal.y)
  );
}

// outputs nx,ny between -1 and +1, nz is the same but its sign is lost
vec3 unpackRGBAToNormal( const vec4 packedNormals ) {
  vec3 normal = vec3(
    unpackRGToDepth(packedNormals.xy) * 2. - 1.,
    unpackRGToDepth(packedNormals.zw) * 2. - 1.,
    0.
  );

  // x is double packed (see packing code)
  normal.z = sign(normal.x);
  normal.x = abs(normal.x);
  normal.x = normal.x * 2. - 1.;

  normal.z *= sqrt(max(0., 1. - normal.x*normal.x - normal.y*normal.y));

  return normal;
}
`;

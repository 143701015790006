import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import {
  useCursor,
  MeshReflectorMaterial,
  Image,
  Text,
  Environment
} from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing } from 'maath'
import getUuid from 'uuid-by-string'
import { RenderTexture } from './RenderTexture'
import { EffectComposer, ToneMapping } from '@react-three/postprocessing'
import { ToneMappingMode } from 'postprocessing'
import './styles.css'
const GOLDENRATIO = 1.61803398875

const DISTANCE_FROM_FRAME = 1.25
const FRAME_THICKNESS = 0.05

const FrameSizing = {
  MATBOARD_SIZE_RATIO: new THREE.Vector2(0.9, 0.93), // the white frame portion, https://images.squarespace-cdn.com/content/v1/578ae24db3db2b247146d614/1541651519695-DI17HRA2CPW5OMO42FB7/Conservation+framing+diagram?format=2500w
  IMAGE_SIZE_RATIO: new THREE.Vector2(0.85, 0.9) // the image inside the matboard
}
const ColorChanger = ({
  active,
  activeColor,
  setActiveColor,
  disabled,
  setDisabled,
  setHasLoaded
}) => {
  const colors = [
    {
      name: 'original',
      hex: '#9fd1c2'
    },
    {
      name: 'pink',
      hex: '#de959e'
    },
    { name: 'orange', hex: '#e0a969' },
    {
      name: 'emerald',
      hex: '#2E7559'
    },
    {
      name: 'purple',
      hex: '#835E5F'
    },
    {
      name: 'black',
      hex: '#272727'
    },
    {
      name: 'navy',
      hex: '#43557D'
    },
    { name: 'pale grey', hex: '#E3E3D9' }
  ]

  const changeColor = newColor => {
    if (disabled) return
    setDisabled(true)
    setActiveColor(newColor)
    setTimeout(() => {
      setDisabled(false)
    }, 2000)
    // Here you can add your logic to change the color of the GLB file
  }
  function lightenDarkenColor (col, amt) {
    var usePound = false
    if (col[0] == '#') {
      col = col.slice(1)
      usePound = true
    }

    var num = parseInt(col, 16)
    var r = (num >> 16) + amt
    if (r > 255) r = 255
    else if (r < 0) r = 0
    var b = ((num >> 8) & 0x00ff) + amt
    if (b > 255) b = 255
    else if (b < 0) b = 0
    var g = (num & 0x0000ff) + amt
    if (g > 255) g = 255
    else if (g < 0) g = 0
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
  }
  if (!active) return null
  return (
    <div
      style={{
        position: 'fixed',
        top: '60%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '10',
        flexWrap: 'wrap',
        maxWidth: '250px'
      }}
    >
      {colors.map(color => {
        return (
          <div
            key={color.name}
            style={{
              background: `linear-gradient(${color.hex}, ${lightenDarkenColor(
                color.hex,
                30
              )})`,
              width: 50,
              height: 50,
              cursor: 'pointer',
              margin: '5px',
              border: activeColor === color.hex ? '2px solid black' : 'none',
              opacity: disabled ? 0.75 : 1
            }}
            onClick={e => {
              e.stopPropagation()
              changeColor(color.hex)
            }}
          />
        )
      })}
    </div>
  )
}

export const App = ({ images }) => {
  const [active, setActive] = useState(false)
  const [color, setColor] = useState('#9fd1c2')
  const [disabled, setDisabled] = useState(false)
  return (
    <div style={{ height: '100vh', width: '100vw', margin: '0' }}>
      <ColorChanger
        active={active}
        setActiveColor={setColor}
        activeColor={color}
        disabled={disabled}
        setDisabled={setDisabled}
      />
      <Canvas
        dpr={[1, 1.5]}
        camera={{ fov: 70, position: [0, 2, 15], name: 'he' }}
      >
        <color attach='background' args={['#191920']} />
        <fog attach='fog' args={['#191920', 0, 15]} />
        <group position={[0, -0.5, 0]}>
          <Frames
            images={images}
            setActive={setActive}
            active={active}
            color={color}
          />
          <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.1, 0]}>
            <planeGeometry args={[50, 50]} />
            <MeshReflectorMaterial
              blur={[300, 100]}
              resolution={2048}
              mixBlur={1}
              mixStrength={80}
              roughness={1}
              depthScale={1.2}
              minDepthThreshold={0.4}
              maxDepthThreshold={1.4}
              color='#050505'
              metalness={0.5}
            />
          </mesh>
        </group>
        <Environment preset='city' />
      </Canvas>
    </div>
  )
}

function Frames ({
  images,
  q = new THREE.Quaternion(),
  p = new THREE.Vector3(),
  p2 = new THREE.Vector3(),
  setActive,
  color,
  disabled,
  setDisabled
}) {
  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()
  const [portalActive, setPortalActive] = useState(false)

  const camera = useThree(state => state.camera)

  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)

      clicked.current.parent.localToWorld(p.set(0, 0, DISTANCE_FROM_FRAME))
      clicked.current.parent.localToWorld(p2.set(0, 0, DISTANCE_FROM_FRAME * 2))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, 5.5)
      p2.copy(p)
      q.identity()
    }
  })
  useFrame((state, dt) => {
    easing.damp3(
      state.camera.position,
      p,
      // p.clone().lerp(p2, 0.5 + 0.5 * Math.sin(new Date().getTime() / 1000)),
      0.4,
      dt
    )
    easing.dampQ(state.camera.quaternion, q, 0.4, dt)
  })

  return (
    <group
      ref={ref}
      onClick={e => {
        if (portalActive) return
        e.stopPropagation()
        setLocation(
          clicked.current === e.object ? '/' : '/item/' + e.object.name
        )
      }}
      onPointerMissed={() => setLocation('/')}
    >
      {images.map(props => {
        const onSetPortalActive = (active, frameRef) => {
          setPortalActive(active)
          setActive(active)
        }
        return (
          <group position={[0, GOLDENRATIO / 2, 0]}>
            <Frame
              key={props.url}
              onSetPortalActive={onSetPortalActive}
              color={color}
              {...props}
            />
          </group>
        )
      })}
    </group>
  )
}

function Frame ({
  url,
  portal: Portal,
  label,
  c = new THREE.Color(),
  onSetPortalActive,
  color,
  disabled,
  ...props
}) {
  const image = useRef()
  const frame = useRef()
  const portalMesh = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [portalActive, setPortalActive] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = getUuid(label)
  const isActive = params?.id === name
  useCursor(hovered)

  const { camera: mainCamera, gl } = useThree(state => state)

  useFrame((state, dt) => {
    const img = image.current || portalMesh.current
    if (img) {
      if (image.current)
        image.current.material.zoom =
          1.5 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
      easing.damp3(
        img.scale,
        [
          FrameSizing.IMAGE_SIZE_RATIO.x * (!isActive && hovered ? 0.85 : 1),
          FrameSizing.IMAGE_SIZE_RATIO.y * (!isActive && hovered ? 0.905 : 1),
          1
        ],
        0.1,
        dt
      )
    }

    const targetFrameScale = new THREE.Vector3(1, 1, FRAME_THICKNESS)

    if (portalActive && frame.current) {
      const cameraSlopeY =
        Math.tan((mainCamera.fov / 2) * THREE.MathUtils.DEG2RAD) * 2
      const frontOfFrame = frame.current.localToWorld(
        new THREE.Vector3(0, 0, 0.5)
      )
      const camDistanceToFrame = -mainCamera.worldToLocal(frontOfFrame).z

      // When clicking 'Configure', we want to expand the frame to consume the screen
      // Actually, we want the frame's inner image to expand to the size of the screen
      // Therefore we need to use the paddingRatio
      const paddingRatio = FrameSizing.MATBOARD_SIZE_RATIO.clone().multiply(
        FrameSizing.IMAGE_SIZE_RATIO
      )

      const frameHeightNeeded = cameraSlopeY * camDistanceToFrame
      const frameWidthNeeded = frameHeightNeeded * mainCamera.aspect

      targetFrameScale.x = frameWidthNeeded / paddingRatio.x
      targetFrameScale.y = frameHeightNeeded / paddingRatio.y
    }
    easing.dampC(
      frame.current.material.color,
      hovered ? 'orange' : 'white',
      0.3,
      dt
    )
    easing.damp3(frame.current.scale, targetFrameScale, 0.1, dt)
  })

  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={e => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[1, 1, FRAME_THICKNESS]}
      >
        <boxGeometry />
        <meshStandardMaterial
          color='#151515'
          metalness={0.5}
          roughness={0.5}
          envMapIntensity={2}
        />
        <mesh
          ref={frame}
          raycast={() => null}
          scale={[
            FrameSizing.MATBOARD_SIZE_RATIO.x,
            FrameSizing.MATBOARD_SIZE_RATIO.y,
            0.001
          ]}
          position={[0, 0, 0.5]}
        >
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />

          {url && (
            <Image
              raycast={() => null}
              ref={image}
              position={[0, 0, 1]}
              url={url}
            />
          )}
          {Portal && (
            <mesh
              scale={[0.9, 0.9, 0.9]}
              position={[0, 0, 1]}
              raycast={() => null}
              ref={portalMesh}
            >
              <boxGeometry />
              <meshBasicMaterial toneMapped={false} fog={false}>
                <RenderTexture
                  attach='map'
                  width={gl.domElement.width}
                  height={gl.domElement.height}
                >
                  <Portal
                    {...{
                      frame: portalMesh,
                      mainCamera,
                      isActive: portalActive,
                      color: color
                    }}
                  />
                </RenderTexture>
              </meshBasicMaterial>
            </mesh>
          )}
        </mesh>
        {Portal && isActive && (
          <Text
            maxWidth={0.1}
            anchorX='center'
            anchorY='top'
            position={[0, -0.3, 0.66]}
            fontSize={0.1}
            color={'#000000'}
            onClick={e => {
              // if (!isActive) return;
              e.stopPropagation()
              setPortalActive(!portalActive)
              onSetPortalActive(!portalActive, frame)
            }}
          >
            {!portalActive ? 'Configure' : 'Done'}
          </Text>
        )}
      </mesh>
      <Text
        maxWidth={0.1}
        anchorX='left'
        anchorY='top'
        position={[0.55, 0.44, 0]}
        fontSize={0.025}
      >
        {label}
      </Text>
    </group>
  )
}

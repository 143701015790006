import {
  Gltf,
  PerspectiveCamera,
  CameraControls,
  ContactShadows,
  Environment,
  Text,
  Html
} from '@react-three/drei'
import { useThree, useFrame } from '@react-three/fiber'
import { useState, useRef, useEffect } from 'react'
import { Vector3, Color, BackSide } from 'three'
import * as THREE from 'three'
import { DEG2RAD, RAD2DEG } from 'three/src/math/MathUtils.js'
import { EffectComposer, SSAO, Sepia } from '@react-three/postprocessing'
import chroma from 'chroma-js'
import { a, useSpring } from '@react-spring/three'

const DISTANCE_FROM_FRAME = 1.25
const FRAME_THICKNESS = 0.05

export function ConfiguratorScene ({
  frame,
  mainCamera,
  isActive,
  color,
  disabled,
  setDisabled
}) {
  const springProps = useSpring({ color })
  const nestedCamRef = useRef()

  const [nestedSceneRef, setNestedSceneRef] = useState()
  const [gltfRef, setGltfRef] = useState()
  const [pedestalRef, setPedestalRef] = useState()

  const [colorTransition, setColorTransition] = useState([])
  const [colorAmount, setColorAmount] = useState(20)

  const getScale = async (oldColor, newColor) => {
    console.log(oldColor, newColor)
    setColorTransition(chroma.scale([oldColor, newColor]).colors(colorAmount))
    return chroma.scale([oldColor, newColor]).colors(colorAmount)
  }



  useEffect(() => {
    if (gltfRef && color) {
      gltfRef.traverse(async node => {
        if (node.isMesh) {
          let oldColor = {
            r: node.material.color.r,
            g: node.material.color.g,
            b: node.material.color.b
          }

          let colorArray
          if (colorTransition.length > 0) {
            colorArray = await getScale(
              colorTransition[colorTransition.length - 1],
              color
            )
          } else {
            colorArray = await getScale(oldColor, color)
          }
          // node.material.color.set(color)
          colorArray.forEach((item, index) => {
            setTimeout(() => {
              console.log(item) // This processing happens after the delay
              node.material.color.set(item)
            }, index * 100) // 1000 ms = 1 second between each log
          })
          // Assuming the material is MeshStandardMaterial or any other material that has 'color' property
          // console.log({ color: hexToRgb(color) })
          // console.log({ node })
        }
      })
    }
  }, [gltfRef, color])

  useFrame(state => {
    if (frame.current && nestedCamRef.current) {
      const obj = frame.current
      new Vector3(0, -0.5, 0).applyMatrix4(obj.matrixWorld)
      obj.updateMatrixWorld(true, true)
      const frameHeight = new Vector3(0, -0.5, 0)
        .applyMatrix4(obj.matrixWorld)
        .distanceTo(new Vector3(0, +0.5, 0).applyMatrix4(obj.matrixWorld))

      const frameWidth = new Vector3(-0.5, 0, 0)
        .applyMatrix4(obj.matrixWorld)
        .distanceTo(new Vector3(+0.5, 0, 0).applyMatrix4(obj.matrixWorld))

      let camDistanceToFrame
      if (isActive) {
        const frontOfFrame = obj.localToWorld(new Vector3(0, 0, 0.5))
        camDistanceToFrame = -mainCamera.worldToLocal(frontOfFrame).z
      } else {
        camDistanceToFrame = DISTANCE_FROM_FRAME - FRAME_THICKNESS / 2
      }

      const mainCameraFovSlope = Math.tan((mainCamera.fov / 2) * DEG2RAD) * 2
      const mainCameraViewHeight = mainCameraFovSlope * camDistanceToFrame
      const frameHeigtRatio = frameHeight / mainCameraViewHeight
      const innerCamFov = Math.atan(frameHeigtRatio / 2) * RAD2DEG * 2
      nestedCamRef.current.fov = innerCamFov
      nestedCamRef.current.aspect = frameWidth / frameHeight
      nestedCamRef.current.updateProjectionMatrix()

      // solve for inner FOV
    }
  })

  if (nestedSceneRef) {
    nestedSceneRef.traverse(child => {
      if (child.material && child.material.type !== 'MeshBasicMaterial') {
        child.castShadow = true
        child.receiveShadow = true
        child.material.envMapIntensity = 0.1
      }
    })
  }
  return (
    <>
      <scene ref={setNestedSceneRef}>
        <CameraControls
          enabled={isActive}
          maxPolarAngle={(Math.PI / 2) * 0.9}
        />
        <PerspectiveCamera
          name='nested cam'
          makeDefault
          manual
          aspect={1 / 1}
          position={[3, 3, 3]}
          fov={mainCamera.fov}
          far={2000}
          near={0.1}
          ref={nestedCamRef}
        />
        <Environment
          files='/ibl/threekit-default-env.hdr'
          environmentIntensity={0}
        />
        <Skybox />

        <group position={[0, -0.08, 0]}>
          {/* <ColorChanger /> */}
          <Gltf
            src='/models/toilet.glb'
            ref={setGltfRef}
            position={[0, 0.18, 0.1]}
          />
          <Gltf src='/models/pedestal.glb' ref={setPedestalRef} />
        </group>
        <SpotLight />
        <mesh rotation={[-Math.PI / 2, 0, 0]} scale={1000} receiveShadow>
          <planeGeometry />
          <meshPhysicalMaterial />
        </mesh>
      </scene>
    </>
  )
}

// skybox color was manually matched via a color picker :)
const Skybox = () => {
  return (
    <mesh scale={500}>
      <sphereGeometry />
      <meshBasicMaterial side={BackSide} color={'#6D6D6D'} toneMapped={false} />
    </mesh>
  )
}

const SpotLight = ({ pos = new Vector3(1, 4, 3) }) => {
  const angle = 0.5
  const coneLength = 20
  const coneWidth = coneLength * Math.tan(angle)
  const penumbra = 0.3
  const numLightShafts = 20

  const [coneRef, setConeRef] = useState()

  return (
    <>
      <spotLight
        visible={true}
        position={pos}
        castShadow={true}
        shadow-bias={-0.001}
        angle={angle}
        shadow-mapSize={1024}
        penumbra={penumbra}
        intensity={0.7}
      >
        {Array.from({ length: numLightShafts }, (_, i) => {
          const width =
            (1 - penumbra + (penumbra * i) / numLightShafts) * coneWidth
          return (
            <group
              quaternion={new THREE.Quaternion().setFromUnitVectors(
                new Vector3(0, 1, 0),
                pos.clone().normalize()
              )}
            >
              <mesh
                castShadow={false}
                receiveShadow={false}
                scale={[width, coneLength, width]}
                position={[0, -coneLength / 2, 0]}
              >
                <coneGeometry />
                <meshBasicMaterial transparent opacity={0.01} />
              </mesh>
            </group>
          )
        })}
      </spotLight>
    </>
  )
}

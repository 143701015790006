import { createRoot } from "react-dom/client";
import "./styles.css";
import { App } from "./App";
import { ConfiguratorScene } from "./ConfiguratorScene";

const pexel = (id) =>
  `https://images.pexels.com/photos/${id}/pexels-photo-${id}.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`;
const images = [
  // Front
  {
    label: "Brazn",
    position: [0, 0, 1.5],
    rotation: [0, 0, 0],
    url: "https://solutions-engineering.s3.amazonaws.com/kohler/raw/brazn.png",
  },
  // Back
  {
    label: "Siphonic",
    position: [-0.8, 0, -0.6],
    rotation: [0, 0, 0],
    url: "https://solutions-engineering.s3.amazonaws.com/kohler/raw/One-piece-s-trap-siphonic-water-saving-colored-egg-shape-toilet-bowl.jpg_.jpeg",
  },
  {
    label: "Kohler Comfort",
    position: [0.8, 0, -0.6],
    rotation: [0, 0, 0],
    url: "https://solutions-engineering.s3.amazonaws.com/kohler/raw/Kohler%2BK-3901-NPR-0%2BNumi_%2BComfort%2BHeightA%CC%82%C2%AE%2BOne-Piece%2BElongated%2BDual-Flush%2BIntelligent%2BToilet%2Bwith%2BSkirted%2BTrapway%2Band%2BPremium%2BRemote.webp",
  },
  // Left
  {
    label: "Inspired",
    position: [-1.75, 0, 0.25],
    rotation: [0, Math.PI / 2.5, 0],
    url: "https://solutions-engineering.s3.amazonaws.com/kohler/raw/Inspired_Selling_4_Cards-aae92434_rgb.jpg",
  },
  {
    label: "Dark Granite",
    position: [-2.15, 0, 1.5],
    rotation: [0, Math.PI / 2.5, 0],
    url: "https://solutions-engineering.s3.amazonaws.com/kohler/raw/hero-dark.webp",
  },
  {
    label: "Complete Collection",
    position: [-2, 0, 2.75],
    rotation: [0, Math.PI / 2.5, 0],
    // url: "https://solutions-engineering.s3.amazonaws.com/kohler/raw/rainbow.jpeg",
    portal: ConfiguratorScene,
  },
  // Right
  {
    label: "Brazn Bathroom",
    position: [1.75, 0, 0.25],
    rotation: [0, -Math.PI / 2.5, 0],
    url: "https://solutions-engineering.s3.amazonaws.com/kohler/raw/brazn.jpeg",
  },
  {
    label: "Modern",
    position: [2.15, 0, 1.5],
    rotation: [0, -Math.PI / 2.5, 0],
    url: "https://solutions-engineering.s3.amazonaws.com/kohler/raw/3f6f32ad16117127c5284c1ee9b197df.jpg",
  },

  {
    label: "Luxurious",
    position: [2, 0, 2.75],
    rotation: [0, -Math.PI / 2.5, 0],
    url: "https://solutions-engineering.s3.amazonaws.com/kohler/s-l1600.jpg",
  },
];

createRoot(document.getElementById("root")).render(<App images={images} />);

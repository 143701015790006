import * as THREE from "three";
import * as React from "react";
import { createPortal, useFrame, useThree } from "@react-three/fiber";
import { useFBO } from "./useFBO";
import { EffectComposer } from "three-stdlib";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { SAOPass } from "./sao/SAOPass.js";

export const RenderTexture = React.forwardRef(
  (
    {
      children,
      compute,
      width,
      height,
      samples = 8,
      renderPriority = 0,
      eventPriority = 0,
      frames = Infinity,
      stencilBuffer = false,
      depthBuffer = true,
      generateMipmaps = false,
      ...props
    },
    forwardRef
  ) => {
    const { size, viewport } = useThree();
    const fbo = useFBO(
      (width || size.width) * viewport.dpr,
      (height || size.height) * viewport.dpr,
      {
        samples,
        stencilBuffer,
        depthBuffer,
        generateMipmaps,
      }
    );
    const [vScene] = React.useState(() => new THREE.Scene());

    const uvCompute = React.useCallback((event, state, previous) => {
      // Since this is only a texture it does not have an easy way to obtain the parent, which we
      // need to transform event coordinates to local coordinates. We use r3f internals to find the
      // next Object3D.
      let parent = fbo.texture?.__r3f.parent;
      while (parent && !(parent instanceof THREE.Object3D)) {
        parent = parent.__r3f.parent;
      }
      if (!parent) return false;
      // First we call the previous state-onion-layers compute, this is what makes it possible to nest portals
      if (!previous.raycaster.camera)
        previous.events.compute(
          event,
          previous,
          previous.previousRoot?.getState()
        );
      // We run a quick check against the parent, if it isn't hit there's no need to raycast at all
      const [intersection] = previous.raycaster.intersectObject(parent);
      if (!intersection) return false;
      // We take that hits uv coords, set up this layers raycaster, et voilà, we have raycasting on arbitrary surfaces
      const uv = intersection.uv;
      if (!uv) return false;
      state.raycaster.setFromCamera(
        state.pointer.set(uv.x * 2 - 1, uv.y * 2 - 1),
        state.camera
      );
    }, []);

    React.useImperativeHandle(forwardRef, () => fbo.texture, [fbo]);

    return (
      <>
        {createPortal(
          <Container renderPriority={renderPriority} frames={frames} fbo={fbo}>
            {children}
            {/* Without an element that receives pointer events state.pointer will always be 0/0 */}
            <group onPointerOver={() => null} />
          </Container>,
          vScene,
          { events: { compute: compute || uvCompute, priority: eventPriority } }
        )}
        <primitive object={fbo.texture} {...props} />
      </>
    );
  }
);

// The container component has to be separate, it can not be inlined because "useFrame(state" when run inside createPortal will return
// the portals own state which includes user-land overrides (custom cameras etc), but if it is executed in <RenderTexture>'s render function
// it would return the default state.
function Container({ frames, renderPriority, children, fbo }) {
  let count = 0;
  let oldAutoClear;
  let oldXrEnabled;

  const { gl, scene, camera } = useThree();

  const effectComposer = new EffectComposer(gl, fbo.clone());
  effectComposer.renderToScreen = false;
  effectComposer.autoClear = true;
  const renderPass = new RenderPass(scene, camera);
  renderPass.renderToScreen = false;
  effectComposer.addPass(renderPass);

  const saoPass = new SAOPass(scene, camera, false, true, false);
  saoPass.params.saoIntensity = 0.5;
  saoPass.params.worldRadius = 0.1;
  saoPass.params.edgeSharpness = 1;
  saoPass.params.saoNumSamples = 20;
  saoPass.params.output = SAOPass.OUTPUT.Default;

  gl.shadowMap.type = THREE.PCFSoftShadowMap;
  gl.shadowMap.enabled = true;

  useFrame((state) => {
    if (frames === Infinity || count < frames) {
      oldAutoClear = state.gl.autoClear;
      oldXrEnabled = state.gl.xr.enabled;
      const oldToneMapping = state.gl.toneMappingMode;
      state.gl.toneMappingMode = THREE.ACESFilmicToneMapping;

      state.gl.autoClear = true;
      state.gl.xr.enabled = false;
      state.gl.setRenderTarget(fbo);
      saoPass.setSize(fbo.width, fbo.height);
      renderPass.render(state.gl, fbo, fbo);
      saoPass.render(gl, null, fbo);
      //   state.gl.render(state.scene, state.camera);
      state.gl.setRenderTarget(null);
      state.gl.autoClear = oldAutoClear;
      state.gl.xr.enabled = oldXrEnabled;
      state.gl.toneMappingMode = oldToneMapping;
      count++;
    }
  }, renderPriority);
  return <>{children}</>;
}

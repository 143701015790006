/**
 * @author ludobaka / ludobaka.github.io
 * SAO implementation inspired from bhouston previous SAO work
 */

import {
  AddEquation,
  ClampToEdgeWrapping,
  Color,
  CustomBlending,
  DepthTexture,
  DoubleSide,
  DstAlphaFactor,
  DstColorFactor,
  LinearEncoding,
  LinearFilter,
  MathUtils,
  MeshDepthMaterial,
  MeshNormalMaterial,
  NearestFilter,
  NoBlending,
  RGBADepthPacking,
  RGBAFormat,
  ShaderMaterial,
  UniformsUtils,
  UnsignedByteType,
  UnsignedShortType,
  WebGLRenderTarget,
  ZeroFactor,
} from "three";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader.js";
import { UnpackDepthRGBAShader } from "three/examples/jsm/shaders/UnpackDepthRGBAShader.js";
import { FullScreenQuad, Pass } from "three-stdlib";

import {
  SAOBilaterialFilterShader,
  SAODepthMinifyShader,
  SAOShader,
} from "./SAOShader.js";

/**
 * Copied from Threekit.
 * TODO: We can eliminate the need for the composer/scopes, since that's not featured in mainline three.js
 * TODO: Use shared normal pass from the EffectComposer
 * TODO: Use higher quality normal packing and smooth normals
 * TODO: Detect and use depth extension when supported
 */

// Enum of scopes
const ComposerScopes = {
  RENDER: "render",
  PASS: "pass",
  FRAME: "frame",
};

const defaultRTOptions = {
  ["wrapS"]: ClampToEdgeWrapping,
  ["wrapT"]: ClampToEdgeWrapping,
  ["magFilter"]: LinearFilter,
  ["minFilter"]: LinearFilter, // WebGLRenderTarget default is different from Texture's
  ["anisotropy"]: 1,
  ["encoding"]: LinearEncoding,

  // these cannot be changed for an RT (without disposing of it, so we don't), unlike the above options, because these affect the storage size
  ["format"]: RGBAFormat,
  ["type"]: UnsignedByteType,
};

function updateRT(rt, options, defaults) {
  // Do a quick update of the RT's texture parameters.
  // Options for WebGLRenderTarget's textures, and their default value
  const tex = rt.texture;
  for (const optionName in defaults) {
    const optionValue =
      options[optionName] != null ? options[optionName] : defaults[optionName];
    if (tex[optionName] !== optionValue) {
      tex[optionName] = optionValue;
      rt.texture.parametersNeedUpdate = true;
    }
  }
  return rt;
}

const DefaultRenderTargetSource = {
  requestRenderTarget: function (scope, width, height, options) {
    return updateRT(
      new WebGLRenderTarget(width, height, options),
      options,
      defaultRTOptions
    );
  },
};

class SAOPass extends Pass {
  constructor(scene, camera, depthTexture, useNormals, usingPool) {
    super();
    this.scene = scene;
    this.camera = camera;

    this.clear = true;
    this.needsSwap = false;

    this.supportsDepthTextureExtension =
      depthTexture !== undefined ? depthTexture : false;
    this.supportsNormalTexture = useNormals !== undefined ? useNormals : false;

    this.originalClearColor = new Color();
    this.oldClearColor = new Color();
    this.oldClearAlpha = 1;

    this.params = {
      output: SAOPass.OUTPUT.SAO,
      saoIntensity: 1,
      saoNumSamples: 20,
      threshold: 0.0,
      worldRadius: 0.2,

      saoBlur: true,
      edgeSharpness: 1,
      blurKernelSize: 8,
    };

    this.depthMaterial = new MeshDepthMaterial();
    this.depthMaterial.depthPacking = RGBADepthPacking;
    this.depthMaterial.blending = NoBlending;
    this.depthMaterial.side = DoubleSide;

    this.normalMaterial = new MeshNormalMaterial();
    this.normalMaterial.blending = NoBlending;
    this.normalMaterial.side = DoubleSide;
    this.normalMaterial.flatShading = true; // on some models, curved pieces became darkened

    this.saoMaterial = new ShaderMaterial({
      defines: Object.assign({}, SAOShader.defines),
      fragmentShader: SAOShader.fragmentShader,
      vertexShader: SAOShader.vertexShader,
      uniforms: UniformsUtils.clone(SAOShader.uniforms),
    });
    this.saoMaterial.extensions.derivatives = true;
    this.saoMaterial.defines["DEPTH_PACKING"] = this
      .supportsDepthTextureExtension
      ? 0
      : 1;
    this.saoMaterial.defines["NORMAL_TEXTURE"] = this.supportsNormalTexture
      ? 1
      : 0;

    this.saoMaterial.blending = NoBlending;

    this.depthMinifyMaterial = new ShaderMaterial(SAODepthMinifyShader);
    this.depthMinifyMaterial.uniforms = UniformsUtils.clone(
      this.depthMinifyMaterial.uniforms
    );
    this.depthMinifyMaterial.defines = Object.assign(
      {},
      this.depthMinifyMaterial.defines
    );
    this.depthMinifyMaterial.blending = NoBlending;

    this.bilateralFilterMaterial = new ShaderMaterial(
      SAOBilaterialFilterShader
    );
    this.bilateralFilterMaterial.uniforms = UniformsUtils.clone(
      this.bilateralFilterMaterial.uniforms
    );
    this.bilateralFilterMaterial.defines = Object.assign(
      {},
      this.bilateralFilterMaterial.defines
    );
    this.bilateralFilterMaterial.blending = NoBlending;
    this.bilateralFilterMaterial.premultipliedAlpha = true;
    this.materialCopy = new ShaderMaterial({
      uniforms: UniformsUtils.clone(CopyShader.uniforms),
      vertexShader: CopyShader.vertexShader,
      fragmentShader: CopyShader.fragmentShader,
      blending: NoBlending,
    });
    this.materialCopy.transparent = true;
    this.materialCopy.depthTest = false;
    this.materialCopy.depthWrite = false;
    this.materialCopy.blending = CustomBlending;
    this.materialCopy.blendSrc = DstColorFactor;
    this.materialCopy.blendDst = ZeroFactor;
    this.materialCopy.blendEquation = AddEquation;
    this.materialCopy.blendSrcAlpha = DstAlphaFactor;
    this.materialCopy.blendDstAlpha = ZeroFactor;
    this.materialCopy.blendEquationAlpha = AddEquation;

    this.depthCopy = new ShaderMaterial({
      uniforms: UniformsUtils.clone(UnpackDepthRGBAShader.uniforms),
      vertexShader: UnpackDepthRGBAShader.vertexShader,
      fragmentShader: UnpackDepthRGBAShader.fragmentShader,
      blending: NoBlending,
    });

    this.fsQuad = new FullScreenQuad(null);

    this.usingPool = usingPool;
    if (!usingPool) {
      this._setupRenderTarget(DefaultRenderTargetSource, 1, 1);
    }
  }

  static OUTPUT = {
    Beauty: 1,
    Default: 0,
    SAO: 2,
    Depth: 3,
    DepthRGBA: 4,
    Normal: 5,
    SamplePattern: 6,
  };

  _setupRenderTarget(renderTargetPool, width, height) {
    const options = {
      minFilter: NearestFilter,
      magFilter: NearestFilter,
      format: RGBAFormat,
    };
    this.saoRenderTarget = renderTargetPool.requestRenderTarget(
      ComposerScopes.PASS,
      width,
      height,
      options,
      "saoRenderTarget"
    );

    this.blurIntermediateRenderTarget = renderTargetPool.requestRenderTarget(
      ComposerScopes.PASS,
      width,
      height,
      options,
      "blurIntermediateRenderTarget"
    );
    this.beautyRenderTarget = renderTargetPool.requestRenderTarget(
      ComposerScopes.PASS,
      width,
      height,
      options,
      "beautyRenderTarget"
    );

    // FUTURE: WE don't need this if supportsNormalTexture is false
    this.normalRenderTarget = renderTargetPool.requestRenderTarget(
      ComposerScopes.PASS,
      width,
      height,
      options,
      "normalRenderTarget"
    );

    // FUTURE: we don't need a lvl 0 if supportsDepthTextureExtension is true
    this.depthRenderTarget = renderTargetPool.requestRenderTarget(
      ComposerScopes.PASS,
      width,
      height,
      options,
      "depthRenderTarget"
    );
    // throw "SET THE SIZE CORRECTLY!"
    {
      let mipWidth = width;
      let mipHeight = height;

      for (let i = 1; i <= 3; i++) {
        mipWidth /= 2;
        mipHeight /= 2;
        // depth[1,2,3]RenderTarget
        this["depth" + i + "RenderTarget"] =
          renderTargetPool.requestRenderTarget(
            ComposerScopes.PASS,
            Math.max(1, Math.floor(mipWidth)),
            Math.max(1, Math.floor(mipHeight)),
            options,
            "depth" + i + "RenderTarget"
          );
      }
    }

    if (this.supportsDepthTextureExtension) {
      var depthTexture = new DepthTexture();
      depthTexture.type = UnsignedShortType;
      depthTexture.minFilter = NearestFilter;
      depthTexture.maxFilter = NearestFilter;

      this.beautyRenderTarget.depthTexture = depthTexture;
      this.beautyRenderTarget.depthBuffer = true;
    }

    this.saoMaterial.uniforms["tDepth"].value = this.depthRenderTarget.texture; // FIXME: Isn't this wrong when depth texture ext. is enabled?
    this.saoMaterial.uniforms["tDepth1"].value =
      this.depth1RenderTarget.texture;
    this.saoMaterial.uniforms["tDepth2"].value =
      this.depth2RenderTarget.texture;
    this.saoMaterial.uniforms["tDepth3"].value =
      this.depth3RenderTarget.texture;
    this.saoMaterial.uniforms["tNormal"].value =
      this.normalRenderTarget.texture;
  }

  render(
    renderer,
    writeBuffer,
    readBuffer,
    deltaTime,
    maskActive,
    renderTargetPool
  ) {
    if (this.usingPool) {
      this._setupRenderTarget(renderTargetPool, this.width, this.height);
    }
    var debugSamplePattern =
      this.params.output === SAOPass.OUTPUT.SamplePattern;
    var blur = this.params.saoBlur && !debugSamplePattern;

    // Rendering readBuffer first when rendering to screen
    if (this.renderToScreen) {
      this.materialCopy.blending = NoBlending;
      this.materialCopy.uniforms["tDiffuse"].value = readBuffer.texture;
      this.materialCopy.needsUpdate = true;
      this.renderPass(renderer, this.materialCopy, null);
    }

    if (this.params.output === 1) {
      return;
    }

    var oldVisibilityFunc = renderer.overrideMaterialFunc;
    if (this.visibilityFunc)
      renderer.overrideMaterialFunc = this.visibilityFunc;

    renderer.getClearColor(this.oldClearColor);
    this.oldClearAlpha = renderer.getClearAlpha();
    var oldAutoClear = renderer.autoClear;
    renderer.autoClear = false;

    renderer.setRenderTarget(this.depthRenderTarget);
    renderer.clear();

    var isPerspective =
      this.camera.isPerspectiveCamera || this.camera.inPerspectiveMode ? 1 : 0;
    [
      this.saoMaterial,
      this.bilateralFilterMaterial,
      this.depthMinifyMaterial,
    ].forEach((material) => {
      if (material.defines["PERSPECTIVE_CAMERA"] !== isPerspective) {
        material.defines["PERSPECTIVE_CAMERA"] = isPerspective;
        material.needsUpdate = true;
      }
    });

    var numSamples = Math.round(this.params.saoNumSamples);
    if (this.saoMaterial.defines.NUM_SAMPLES !== numSamples) {
      this.saoMaterial.defines.NUM_SAMPLES = numSamples;
      this.saoMaterial.needsUpdate = true;
    }

    this.saoMaterial.uniforms["cameraInverseProjectionMatrix"].value
      .copy(this.camera.projectionMatrix)
      .invert();
    this.saoMaterial.uniforms["cameraProjectionMatrix"].value =
      this.camera.projectionMatrix;
    // more samples will darken the screen, Math.pow makes it perceptually more consistent
    this.saoMaterial.uniforms["intensity"].value = this.params.saoIntensity;
    this.saoMaterial.uniforms["worldRadius"].value = this.params.worldRadius;
    this.saoMaterial.uniforms["invWorldRadius"].value =
      1 / this.params.worldRadius;
    this.saoMaterial.uniforms["threshold"].value = this.params.threshold;
    this.saoMaterial.uniforms["cameraNear"].value = this.camera.near;
    this.saoMaterial.uniforms["cameraFar"].value = this.camera.far;
    this.saoMaterial.uniforms["invTanFov"].value =
      1 / Math.tan((MathUtils.DEG2RAD * this.camera.fov) / 2);
    this.saoMaterial.uniforms["aspect"].value = 1 / this.camera.aspect;
    this.saoMaterial.uniforms["packOutput"].value = blur;

    if (debugSamplePattern !== this.saoMaterial.defines["debugSamplePattern"]) {
      this.saoMaterial.defines["DEBUG_SAMPLE_PATTERN"] = debugSamplePattern
        ? 1
        : 0;
      this.saoMaterial.needsUpdate = true;
    }

    //

    if (
      this.params.output === SAOPass.OUTPUT.Beauty ||
      this.params.output === SAOPass.OUTPUT.Default ||
      this.supportsDepthTextureExtension
    ) {
      // Rendering scene to depth texture
      renderer.setClearColor(0x000000);
      renderer.setRenderTarget(this.beautyRenderTarget);
      renderer.clear();
      renderer.render(this.scene, this.camera);
    }

    const hidden = new Set();
    this.scene.traverseVisible((obj) => {
      if (obj.material?.type === "MeshBasicMaterial") {
        hidden.add(obj);
        obj.visible = false;
      }
    });

    {
      // Re-render scene if depth texture extension is not supported
      if (!this.supportsDepthTextureExtension) {
        // Clear rule : far clipping plane in both RGBA and Basic encoding
        this.renderOverride(
          renderer,
          this.depthMaterial,
          this.depthRenderTarget,
          0xffffff,
          1.0
        );
      }

      if (this.supportsNormalTexture) {
        this.renderOverride(
          renderer,
          this.normalMaterial,
          this.normalRenderTarget,
          0x000000,
          0.0
        );
      }
    }

    for (const obj of hidden) {
      obj.visible = true;
    }

    this.depthMinifyMaterial.uniforms["cameraNear"].value = this.camera.near;
    this.depthMinifyMaterial.uniforms["cameraFar"].value = this.camera.far;

    let depthRT = this.depthRenderTarget;
    [
      this.depth1RenderTarget,
      this.depth2RenderTarget,
      this.depth3RenderTarget,
    ].forEach((newRT) => {
      this.depthMinifyMaterial.uniforms["tDepth"].value = depthRT.texture;
      this.depthMinifyMaterial.uniforms["size"].value.set(
        depthRT.width,
        depthRT.height
      );
      this.depthMinifyMaterial.uniforms["invSize"].value.set(
        1 / depthRT.width,
        1 / depthRT.height
      );
      this.renderPass(renderer, this.depthMinifyMaterial, newRT, 0xffffff, 1);
      depthRT = newRT;
    });

    //

    renderer.overrideMaterialFunc = oldVisibilityFunc;

    // Rendering SAO texture
    this.renderPass(
      renderer,
      this.saoMaterial,
      this.saoRenderTarget,
      0x777777,
      1.0
    );

    // Blurring SAO texture
    if (blur) {
      this.bilateralFilterMaterial.defines["KERNEL_SAMPLE_RADIUS"] =
        this.params.blurKernelSize;
      this.bilateralFilterMaterial.uniforms["cameraNear"].value =
        this.camera.near;
      this.bilateralFilterMaterial.uniforms["cameraFar"].value =
        this.camera.far;
      this.bilateralFilterMaterial.uniforms["tAONormal"].value =
        this.normalRenderTarget.texture;
      this.bilateralFilterMaterial.uniforms["edgeSharpness"].value =
        this.params.edgeSharpness;

      this.bilateralFilterMaterial.uniforms["tAODepth"].value =
        this.saoRenderTarget.texture;
      this.bilateralFilterMaterial.uniforms["kernelDirection"].value.set(1, 0);
      this.bilateralFilterMaterial.uniforms["packOutput"].value = 1;

      this.renderPass(
        renderer,
        this.bilateralFilterMaterial,
        this.blurIntermediateRenderTarget,
        0xffffff,
        1.0
      ); // , 0xffffff, 0.0, "sao vBlur"

      this.bilateralFilterMaterial.uniforms["tAODepth"].value =
        this.blurIntermediateRenderTarget.texture;
      this.bilateralFilterMaterial.uniforms["kernelDirection"].value.set(0, 1);
      this.bilateralFilterMaterial.uniforms["packOutput"].value = 0;

      this.renderPass(
        renderer,
        this.bilateralFilterMaterial,
        this.saoRenderTarget,
        0xffffff,
        0.0
      ); // 0xffffff, 0.0, "sao hBlur"
    }

    var outputMaterial = this.materialCopy;

    // Setting up SAO rendering
    if (this.params.output === SAOPass.OUTPUT.Depth) {
      if (this.supportsDepthTextureExtension) {
        this.materialCopy.uniforms["tDiffuse"].value =
          this.beautyRenderTarget.depthTexture;
        this.materialCopy.needsUpdate = true;
      } else {
        let i = Math.round(new Date().getTime() / 1000) % 4;
        let rt =
          i == 0 ? this.depthRenderTarget : this["depth" + i + "RenderTarget"];
        this.depthCopy.uniforms["tDiffuse"].value = rt.texture;
        this.depthCopy.uniforms["cameraNear"].value = this.camera.near;
        this.depthCopy.uniforms["cameraFar"].value = this.camera.far;
        this.depthCopy.needsUpdate = true;
        outputMaterial = this.depthCopy;
      }
    } else if (this.params.output === SAOPass.OUTPUT.DepthRGBA) {
      let i = Math.round(new Date().getTime() / 1000) % 4;
      let rt =
        i == 0 ? this.depthRenderTarget : this["depth" + i + "RenderTarget"];
      this.materialCopy.uniforms["tDiffuse"].value = rt.texture;
      this.materialCopy.needsUpdate = true;
      outputMaterial = this.materialCopy;
    } else if (this.params.output === SAOPass.OUTPUT.Normal) {
      this.materialCopy.uniforms["tDiffuse"].value =
        this.normalRenderTarget.texture;
      this.materialCopy.needsUpdate = true;
    } else if (
      this.params.output === SAOPass.OUTPUT.Default ||
      this.params.output === SAOPass.OUTPUT.Beauty ||
      this.params.output === SAOPass.OUTPUT.SAO ||
      this.params.output === SAOPass.OUTPUT.SamplePattern
    ) {
      // this.materialCopy.uniforms[ 'tDiffuse' ].value = this.blurIntermediateRenderTarget.texture;
      this.materialCopy.uniforms["tDiffuse"].value =
        this.saoRenderTarget.texture;
      this.materialCopy.needsUpdate = true;
    }

    // Blending depends on output, only want a CustomBlending when showing SAO
    if (this.params.output === SAOPass.OUTPUT.Default) {
      outputMaterial.blending = CustomBlending;
    } else {
      // SAOPass.SAO or SAOPass.SamplePattern

      outputMaterial.blending = NoBlending;
    }

    // Rendering SAOPass result on top of previous pass
    this.renderPass(
      renderer,
      outputMaterial,
      this.renderToScreen ? null : readBuffer
    );

    renderer.setClearColor(this.oldClearColor, this.oldClearAlpha);
    renderer.autoClear = oldAutoClear;
  }

  renderPass(renderer, passMaterial, renderTarget, clearColor, clearAlpha) {
    // save original state
    renderer.getClearColor(this.originalClearColor);
    var originalClearAlpha = renderer.getClearAlpha();
    var originalAutoClear = renderer.autoClear;
    var oldSceneBackground = this.scene.background;

    renderer.setRenderTarget(renderTarget);

    // setup pass state
    renderer.autoClear = false;
    if (clearColor !== undefined && clearColor !== null) {
      renderer.setClearColor(clearColor);
      renderer.setClearAlpha(clearAlpha || 0.0);
      renderer.clear();
      this.scene.background = null;
    }

    this.fsQuad.material = passMaterial;
    this.fsQuad.render(renderer);

    // restore original state
    renderer.autoClear = originalAutoClear;
    renderer.setClearColor(this.originalClearColor);
    renderer.setClearAlpha(originalClearAlpha);
    this.scene.background = oldSceneBackground;
  }

  renderOverride(
    renderer,
    overrideMaterial,
    renderTarget,
    clearColor,
    clearAlpha
  ) {
    var oldSceneBackground = this.scene.background;

    renderer.getClearColor(this.originalClearColor);
    var originalClearAlpha = renderer.getClearAlpha();
    var originalAutoClear = renderer.autoClear;

    renderer.setRenderTarget(renderTarget);
    renderer.autoClear = false;

    clearColor = overrideMaterial.clearColor || clearColor;
    clearAlpha = overrideMaterial.clearAlpha || clearAlpha;
    if (clearColor !== undefined && clearColor !== null) {
      renderer.setClearColor(clearColor);
      renderer.setClearAlpha(clearAlpha || 0.0);
      renderer.clear();
      this.scene.background = null;
    }

    this.scene.overrideMaterial = overrideMaterial;
    renderer.render(this.scene, this.camera);
    this.scene.overrideMaterial = null;

    // restore original state
    renderer.autoClear = originalAutoClear;
    renderer.setClearColor(this.originalClearColor);
    renderer.setClearAlpha(originalClearAlpha);
    this.scene.background = oldSceneBackground;
  }

  setSize(width, height) {
    if (!this.usingPool) {
      this.beautyRenderTarget.setSize(width, height);
      this.saoRenderTarget.setSize(width, height);
      this.blurIntermediateRenderTarget.setSize(width, height);
      this.normalRenderTarget.setSize(width, height);

      this.depthRenderTarget.setSize(width, height);
      {
        var mipWidth = width;
        var mipHeight = height;
        for (var i = 1; i <= 3; i++) {
          mipWidth /= 2;
          mipHeight /= 2;
          var rt = this["depth" + i + "RenderTarget"];
          rt.setSize(
            Math.max(1, Math.floor(mipWidth)),
            Math.max(1, Math.floor(mipHeight))
          );
        }
      }
    } else {
      this.width = width;
      this.height = height;
    }

    this.saoMaterial.uniforms["size"].value.set(width, height);
    this.saoMaterial.uniforms["invSize"].value.set(1 / width, 1 / height);

    this.bilateralFilterMaterial.uniforms["size"].value.set(width, height);
  }
}

export { SAOPass };

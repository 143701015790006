import { Matrix4, NoBlending, Vector2 } from 'three';

import Packing from './packing.js';

/**
 * Copied from Threekit.
 */

var SAOShader = {
  defines: {
    NUM_SAMPLES: 32,
    NORMAL_TEXTURE: 0,
    DIFFUSE_TEXTURE: 0,
    DEPTH_PACKING: 1,
    PERSPECTIVE_CAMERA: 1,
    DEBUG_SAMPLE_PATTERN: 0
  },
  uniforms: {
    tDepth: { value: null },
    tDepth1: { value: null },
    tDepth2: { value: null },
    tDepth3: { value: null },
    tDiffuse: { value: null },
    tNormal: { value: null },
    size: { value: new Vector2(512, 512) },
    invSize: { value: new Vector2(1 / 512, 1 / 512) },

    cameraNear: { value: 1 },
    cameraFar: { value: 100 },
    cameraProjectionMatrix: { value: new Matrix4() },
    cameraInverseProjectionMatrix: { value: new Matrix4() },
    invTanFov: { value: 1 },
    aspect: { value: 1 },

    intensity: { value: 0.1 },
    worldRadius: { value: 1 },
    invWorldRadius: { value: 1 },
    threshold: { value: 1 },
    bias: { value: 0.5 },

    randomSeed: { value: 0.0 },

    packOutput: { value: false }
  },
  vertexShader: [
    'varying vec2 vUv;',

    'void main() {',
    '	vUv = uv;',
    '	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
    '}'
  ].join('\n'),
  fragmentShader: [
    '#include <common>',

    '#include <packing>',
    Packing,

    'varying vec2 vUv;',

    '#if DIFFUSE_TEXTURE == 1',
    'uniform sampler2D tDiffuse;',
    '#endif',

    'uniform sampler2D tDepth;',
    'uniform sampler2D tDepth1;',
    'uniform sampler2D tDepth2;',
    'uniform sampler2D tDepth3;',

    '#if NORMAL_TEXTURE == 1',
    'uniform sampler2D tNormal;',
    '#endif',

    'uniform float cameraNear;',
    'uniform float cameraFar;',
    'uniform mat4 cameraProjectionMatrix;',
    'uniform mat4 cameraInverseProjectionMatrix;',
    'uniform float invTanFov;',
    'uniform float aspect;',

    'uniform float intensity;',
    'uniform float worldRadius;',
    'uniform float invWorldRadius;',
    'uniform float threshold;',
    'uniform float bias;',
    'uniform vec2 size;',
    'uniform vec2 invSize;',
    'uniform float randomSeed;',

    'uniform bool packOutput;',

    '// RGBA depth',

    'vec4 getDefaultColor( const in vec2 screenPosition ) {',
    '	#if DIFFUSE_TEXTURE == 1',
    '	return texture2D( tDiffuse, vUv );',
    '	#else',
    '	return vec4( 1.0 );',
    '	#endif',
    '}',

    'int getDepthMip( const in vec2 screenPosition) {',
    '	const float firstMipChange = 1. / 8.;', // after how many pixels do we use mip 1? this is a performance/quality trade-off
    '	vec2 diff = abs(vUv - screenPosition) * size;',
    '	float dist = max(diff.x, diff.y);',
    '	return int( log2( dist * firstMipChange ) + 1. );',
    '}',

    'float getDepth( in vec2 screenPosition, const in int mip ) {',
    '	vec4 packedDepth;',

    ' if( mip <= 0)',
    '		packedDepth = texture2D( tDepth,  screenPosition );',
    ' else if( mip <= 1)',
    '		packedDepth = texture2D( tDepth1, screenPosition );',
    ' else if( mip <= 2)',
    '		packedDepth = texture2D( tDepth2, screenPosition );',
    ' else',
    '		packedDepth = texture2D( tDepth3, screenPosition );',

    '	return unpackRGBAToDepth( packedDepth );',
    '}',

    'float getViewZ( const in float depth ) {',
    '	#if PERSPECTIVE_CAMERA == 1',
    '	return perspectiveDepthToViewZ( depth, cameraNear, cameraFar );',
    '	#else',
    '	return orthographicDepthToViewZ( depth, cameraNear, cameraFar );',
    '	#endif',
    '}',

    'vec3 getViewPosition( const in vec2 screenPosition, const in float depth, const in float viewZ ) {',
    '	float clipW = cameraProjectionMatrix[2][3] * viewZ + cameraProjectionMatrix[3][3];',
    '	vec4 clipPosition = vec4( ( vec3( screenPosition, depth ) - 0.5 ) * 2.0, 1.0 );',
    '	clipPosition *= clipW; // unprojection.',

    '	return ( cameraInverseProjectionMatrix * clipPosition ).xyz;',
    '}',

    'vec3 getViewNormal( const in vec3 viewPosition, const in vec2 screenPosition ) {',
    '	#if NORMAL_TEXTURE == 1',
    '	return unpackRGBToNormal( texture2D( tNormal, screenPosition ).rgb );',
    '	#else',
    '	return normalize( cross( dFdx( viewPosition ), dFdy( viewPosition ) ) );',
    '	#endif',
    '}',

    'float getOcclusion( in vec3 centerViewPosition, const in vec3 centerViewNormal, in vec3 sampleViewPosition, const in float sampleDist, const in float threshold, const in float invThreshold ) {',
    '	vec3 viewDelta = (sampleViewPosition - centerViewPosition) / worldRadius;',

    // these are used later on, calculated once here for performance
    '	float viewDelta_length = length(viewDelta);',
    '	float viewDelta_invLength = 1. / viewDelta_length;',
    '	vec3 viewDelta_normalize = viewDelta * viewDelta_invLength;',

    // error margin
    '	float errorMargin = .05 * viewDelta_length;',
    '	errorMargin += .05;',
    '	errorMargin += .0001 * sampleDist;', // mip maps introduce rounding differences

    '	float distanceToPlane = dot( viewDelta, centerViewNormal);',

    '	distanceToPlane -= errorMargin * viewDelta_length;',
    '	if(distanceToPlane <= 0.) return 0.;',

    '	distanceToPlane -= threshold;', // give artists ability to disable SAO on smaller details
    '	if(distanceToPlane <= 0.) return 0.;',

    '	return distanceToPlane / (dot(viewDelta, viewDelta) + 1.);',
    // this makes far away occlusion fade out, so you don't have black halos around objects against distant scenery
    // divide by distance yields +1. is arbitrary and reduces noise

    '}',

    'void debugSamplePattern(vec2 sampleUv, inout float light) {',
    '		const float scale = 2.;',
    '		vec2 diff = abs(sampleUv - vec2(.5));',
    '		if( all(lessThanEqual(diff / scale, 1. / size)) ) light *= .5;',
    '}',

    '// moving costly divides into consts',
    'const float ANGLE_STEP = 2.39996322972865332;', // https://en.wikipedia.org/wiki/Golden_angle
    'const mat2 rotationMatrix = mat2( cos( ANGLE_STEP ), -sin( ANGLE_STEP ), sin( ANGLE_STEP ), cos( ANGLE_STEP ) );',

    'const float INV_NUM_SAMPLES = 1.0 / float( NUM_SAMPLES );',

    'float getAmbientOcclusion( const in vec3 centerViewPosition ) {',
    '	// precompute some variables require in getOcclusion.',

    '	vec3 centerViewNormal = getViewNormal( centerViewPosition, vUv );',

    // " mat3 vecSpace = matrixFromVector( centerViewNormal );",

    '	float initialAngle = PI2 * rand( vUv + randomSeed );',

    // sample radius
    '	float radiusOffset = rand( vec2(513., 312.) + vec2(51., .1562) * vUv + randomSeed );',

    '	vec2 dir = vec2( cos( initialAngle ), sin( initialAngle ) );',

    '	#if DEBUG_SAMPLE_PATTERN', // please turn off blur when using this
    '		dir = vec2( 1, 0 );',
    '	#endif',

    '	float light = 1.;',

    // ie. how big is 1 world unit in uv space?
    '	vec2 uvSampleArea = worldRadius * invTanFov * vec2(aspect, 1.) / abs(centerViewPosition.z);',

    '	float invThreshold = 1. / (1. - threshold);',

    '	for( int i = 0; i < NUM_SAMPLES; i ++ ) {',
    '		float progress = ( float( i ) + radiusOffset ) * INV_NUM_SAMPLES;',

    '		vec2 sampleOffset = dir * (progress * uvSampleArea);',
    '		sampleOffset += sign(sign(sampleOffset) + vec2(.1)) * invSize * 3.;', // does a great job of eliminating noise by making sure we don't sample too close to the initial position (ie avoid divide by 0 errors)
    '		vec2 sampleUv = vUv + sampleOffset;',
    '		dir *= rotationMatrix;',

    '		#if DEBUG_SAMPLE_PATTERN', // please turn off blur when using this
    '			debugSamplePattern(sampleUv, light);',
    '			continue;',
    '		#endif',

    '		if( sampleUv != saturate( sampleUv ) ) {', // offscreen sample
    '			continue;',
    '		}',

    '		int sampleMip = getDepthMip( sampleUv );',
    '		float sampleDepth = getDepth( sampleUv, sampleMip );',

    '		if( sampleDepth >= ( 1.0 - EPSILON ) || sampleDepth < EPSILON ) {',
    '			continue;',
    '		}',

    '		vec3 sampleViewPosition = getViewPosition( sampleUv, sampleDepth, getViewZ( sampleDepth ) );',

    '		vec2 pixelDist = abs(size * sampleOffset);',
    '		float o = getOcclusion( centerViewPosition, centerViewNormal, sampleViewPosition, max(pixelDist.x, pixelDist.y), threshold, invThreshold );',

    '		float importance = 1. - progress;',
    '		light *= 1. - saturate(o * importance * INV_NUM_SAMPLES);',
    '	}',

    'return 1. - pow(light, 10. * intensity);', // 10x for backwards compatability (approximate)
    '}',

    'void main() {',

    '	vec2 uv = vUv;',
    '	#if DEBUG_SAMPLE_PATTERN',
    '		uv = vec2(.5);',
    '	#endif',

    '	float centerDepth = getDepth( uv, 0 );',
    '	if( centerDepth >= ( 1.0 - EPSILON ) ) {',
    '		gl_FragColor = vec4(1,1,1,0);',
    '		return;',
    '	}',

    '	float centerViewZ = getViewZ( centerDepth );',
    '	vec3 viewPosition = getViewPosition( uv, centerDepth, centerViewZ );',

    '	float ambientOcclusion = getAmbientOcclusion( viewPosition );',

    ' if( packOutput ) {',
    '  gl_FragColor = vec4( max( 1.0 - ambientOcclusion, 0.), packDepthToRGB( centerDepth ) );',
    ' } else {',
    '	 gl_FragColor = getDefaultColor( uv );',
    '	 gl_FragColor.xyz *=  1.0 - ambientOcclusion;',
    ' }',

    '}'
  ].join('\n')
};

var SAOBilaterialFilterShader = {
  blending: NoBlending,

  defines: {
    PERSPECTIVE_CAMERA: 1,
    KERNEL_SAMPLE_RADIUS: 4
  },

  uniforms: {
    tAODepth: { type: 't', value: null },
    tAONormal: { type: 't', value: null },
    size: { type: 'v2', value: new Vector2(256, 256) },

    kernelDirection: { type: 'v2', value: new Vector2(1, 0) },

    cameraNear: { type: 'f', value: 1 },
    cameraFar: { type: 'f', value: 100 },
    edgeSharpness: { type: 'f', value: 1 },
    packOutput: { type: 'f', value: 1 },
    normalWeightMod: { value: 1 }
  },

  vertexShader: [
    'varying vec2 vUv;',

    'void main() {',

    'vUv = uv;',

    'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

    '}'
  ].join('\n'),

  fragmentShader: [
    '#include <common>',

    '#include <packing>',
    Packing,

    'varying vec2 vUv;',

    'uniform sampler2D tAODepth;',
    'uniform sampler2D tAONormal;',
    'uniform vec2 size;',

    'uniform float cameraNear;',
    'uniform float cameraFar;',
    'uniform float edgeSharpness;',
    'uniform int packOutput;',

    'uniform vec2 kernelDirection;',

    'float getViewZ( const in float depth ) {',

    '#if PERSPECTIVE_CAMERA == 1',
    'return perspectiveDepthToViewZ( depth, cameraNear, cameraFar );',
    '#else',
    'return orthographicDepthToViewZ( depth, cameraNear, cameraFar );',
    '#endif',

    '}',

    'void addTapInfluence( const in vec2 tapUv, const in vec3 centerNormal, const in float invCenterViewZ, const in float kernelWeight, inout float aoSum, inout float weightSum ) {',

    'vec4 depthTexel = texture2D( tAODepth, tapUv );',
    'float ao = depthTexel.r;',
    'depthTexel.r = 1.0;',
    'float depth = unpackRGBToDepth( depthTexel.gba );',

    'if( depth >= ( 1.0 - EPSILON ) ) {',
    'return;',
    '}',

    'float tapViewZ = -getViewZ( depth );',
    'float depthError = abs(1. - tapViewZ * invCenterViewZ);',
    'const float depthErrorRange = .01;',

    'float depthWeight = .5 * max(0., depthErrorRange - depthError) * (1. / depthErrorRange);',

    'vec3 normal = unpackRGBAToNormal(texture2D(tAONormal, tapUv));',
    'float normalCloseness = dot(normal, centerNormal);',
    // "float k_normal = 1.0;",
    // "float normalError = (1.0 - pow4( normalCloseness )) * k_normal;",
    // "float normalWeight = normalWeightMod * max((1.0 - edgeSharpness * normalError), 0.00);",

    'float tapWeight = kernelWeight * ( depthWeight * saturate( normalCloseness ) );',

    'aoSum += ao * tapWeight;',
    'weightSum += tapWeight;',
    '}',

    'float normpdf(in float x, in float invSigma) {',
    'return 0.39894*exp(-0.5*x*x*(invSigma*invSigma))*invSigma;',
    '}',

    'void main() {',

    'vec4 depthTexel = texture2D( tAODepth, vUv );',
    'float ao = depthTexel.r;',
    'depthTexel.r = 1.0;',
    'float depth = unpackRGBToDepth( depthTexel.gba );',
    'if( depth >= ( 1.0 - EPSILON ) ) {',
    'gl_FragColor = vec4(1, 1, 1, 0);',
    'return;',
    '}',

    'float centerViewZ = -getViewZ( depth );',
    'float invCenterViewZ = 1. / centerViewZ;',

    'float weightSum = normpdf(0.0, 1. / 5.0) + 0.1;',
    'float aoSum = ao * weightSum;',

    'vec2 uvIncrement = ( kernelDirection / size );',

    'vec2 rTapUv = vUv, lTapUv = vUv;',
    'vec3 normalCenter = unpackRGBAToNormal(texture2D(tAONormal, vUv));',

    'for( int i = 1; i <= KERNEL_SAMPLE_RADIUS; i ++ ) {',

    'float kernelWeight = normpdf(float(i), 1. / 5.0) + 0.1;',

    'rTapUv += uvIncrement;',
    'addTapInfluence( rTapUv, normalCenter, invCenterViewZ, kernelWeight, aoSum, weightSum );',

    'lTapUv -= uvIncrement;',
    'addTapInfluence( lTapUv, normalCenter, invCenterViewZ, kernelWeight, aoSum, weightSum );',

    '}',

    'if ( weightSum > EPSILON) ao = aoSum / weightSum;',
    'else ao = 1.;',
    'if( packOutput == 1 ) {',
    'gl_FragColor = depthTexel;',
    'gl_FragColor.r = ao;',
    '}',
    'else {',
    'gl_FragColor = vec4( vec3( ao ), 1.0 );',
    '}',

    '}'
  ].join('\n')
};

const SAODepthMinifyShader = {
  blending: NoBlending,

  defines: {
    DEPTH_PACKING: 3201,
    //	"JITTERED_SAMPLING": 1
    PERSPECTIVE_CAMERA: 1
  },

  uniforms: {
    tDepth: { type: 't', value: null },
    cameraNear: { type: 'f', value: 1 },
    cameraFar: { type: 'f', value: 100 },
    size: { type: 'v2', value: new Vector2(256, 256) },
    invSize: { type: 'v2', value: new Vector2(1 / 256, 1 / 256) }
  },

  vertexShader: [
    'varying vec2 vUv;',

    'void main() {',

    'vUv = uv;',

    'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

    '}'
  ].join('\n'),

  fragmentShader: [
    '#include <common>',
    '#include <packing>',
    Packing,

    'varying vec2 vUv;',

    'uniform sampler2D tDepth;',
    'uniform vec2 size;',
    'uniform vec2 invSize;',
    'uniform float cameraNear;',
    'uniform float cameraFar;',

    'float getViewZ( const in float depth ) {',
    '	#if PERSPECTIVE_CAMERA == 1',
    '	return perspectiveDepthToViewZ( depth, cameraNear, cameraFar );',
    '	#else',
    '	return orthographicDepthToViewZ( depth, cameraNear, cameraFar );',
    '	#endif',
    '}',

    'float getDepth( const in float viewZ ) {',
    '	#if PERSPECTIVE_CAMERA == 1',
    '	return viewZToPerspectiveDepth( viewZ, cameraNear, cameraFar );',
    '	#else',
    '	return viewZToOrthographicDepth( viewZ, cameraNear, cameraFar );',
    '	#endif',
    '}',

    'void main() {',

    'float viewZ = 0.;',
    'viewZ += getViewZ( unpackRGBAToDepth( texture2D( tDepth, vUv + invSize * vec2( -1.0, -1.0 ) ) ) );',
    'viewZ += getViewZ( unpackRGBAToDepth( texture2D( tDepth, vUv + invSize * vec2( +1.0, +1.0 ) ) ) );',
    'viewZ += getViewZ( unpackRGBAToDepth( texture2D( tDepth, vUv + invSize * vec2( -1.0, +1.0 ) ) ) );',
    'viewZ += getViewZ( unpackRGBAToDepth( texture2D( tDepth, vUv + invSize * vec2( +1.0, -1.0 ) ) ) );',
    'viewZ *= 0.25;',
    'gl_FragColor = packDepthToRGBA( getDepth( viewZ ) );',
    '}'
  ].join('\n')
};

export { SAOShader, SAOBilaterialFilterShader, SAODepthMinifyShader };
